import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { mergeArrays } from '../utils/mergeArrays'
import RelatedItems from '../components/RelatedItems/RelatedItems'
import SEO from '../components/Seo'
import { updateImage } from '../utils/updateImage'

const NotFoundPage = ({ data, pageContext }) => {
  const {
    content: {
      frontmatter: {
        image,
        header,
        description
      }
    },
    seo: {
      frontmatter: {
        seo: seoInfo
      }
    },
    interestingForYouItems: {
      frontmatter: {
        label,
        interestingForYou
      }
    },
    relatedItems: {
      edges
    }
  } = data

  // added order to related item, will use it to
  // show correct orders on Intresting For You section
  const itemsList = interestingForYou
    .map((item, index) => Object.assign({ order: index }, item))

  // created array of objects which will be shown on Interesting for you section
  const intrestingForYouContent = mergeArrays(edges, itemsList)

  return (
    <Fragment>
      <SEO data={ seoInfo } />
      <section className="error-page">
        <div className="top-section"/>
        <div className="error-section wrapper">
          <div className="error-wrapper">
            {image ? (
              <div className="error-image">
                <Img
                // removed largest image size from srcSet, if it exist
                  fluid={{ ...updateImage(image.childImageSharp.fluid),
                    sizes: `(max-width: 440px) 344px, 486px`
                  }}
                  loading="eager"
                />
              </div>
            ) : (
              <h1 className="error-label">404</h1>
            )}
            <p
              className="error-header"
              dangerouslySetInnerHTML={{ __html: header }}
            />
            <p
              className="error-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
        <RelatedItems
          relatedItems={intrestingForYouContent}
          authors={pageContext.authors}
          label={label}
        />
      </section>
    </Fragment>
  )
}

export const notFoundQuery = graphql`
  query($relatedItemsTitle: [String]!) {
    content : markdownRemark (
      fileAbsolutePath: {regex: "/content/additional-content/error-page/text-content.md/"}
    ) {
      frontmatter {
        header
        description
        image {
          childImageSharp {
            fluid (
              maxWidth: 486,
              maxHeight: 177,
              quality: 100,
              srcSetBreakpoints: [344, 486],
              base64Width: 100
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }    
    }
    interestingForYouItems: markdownRemark (
      fileAbsolutePath: {
        regex: "/content/additional-content/error-page/interesting-for-you.md/"
      }
    ) {
      frontmatter {
        label
        interestingForYou {
          name
          image {
            childImageSharp {
              fixed (width: 394, height: 237, quality: 100){
                src
              }
            }
          }
          description
          type
        }
      }
    }
    seo: markdownRemark (
      fileAbsolutePath: { regex: "/content/additional-content/error-page/seo-not-found.md/" }
    ) {
      frontmatter {
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    relatedItems: allMarkdownRemark(
      limit: 3
      filter: {
        frontmatter: { permalink: { in: $relatedItemsTitle }}
        fileAbsolutePath: { regex: "/content/individual-pages/"}
      } 
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            permalink
            title
            author
            authorImage {
              childImageSharp {
                fixed (base64Width: 100){
                  base64
                }
              }
            }
            whatWeDo
            date(formatString: "DD MMM YYYY")
            image {
              childImageSharp {
                fixed (width: 394, height: 237, quality: 100){
                  src
                }
              }
            }
            description
          }
          fields {
            slug
            type
          }
        }
      }
    }
  }
`

export default NotFoundPage
